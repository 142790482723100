import { Loader } from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../globalDomain";
import * as remoteData from "../RemoteData";
import { useQuery } from "../useAPI";
import * as loanApi from "../StandardLoan/api";
import { StandardLoanFlowType } from "../StandardLoan/domain";

type Props = {
  renderChild: (
    authenticationMethod: Option<ExistingClientAuthenticationMethod>
  ) => JSX.Element;
  defaultAuthenticationMethod: Option<ExistingClientAuthenticationMethod>;
  flowType: StandardLoanFlowType;
};

export const AuthMethodCalculateBySignatureType = (props: Props) => {
  const [getSignatureType] = useQuery(loanApi.getSignatureType);

  return pipe(
    getSignatureType,
    remoteData.fold(
      () => <Loader />,
      () => props.renderChild(props.defaultAuthenticationMethod),
      signature =>
        pipe(
          signature.signatureType,
          option.fold(
            () => props.renderChild(props.defaultAuthenticationMethod),
            signatureType =>
              props.renderChild(
                mapSignatureToExistingClientAuthMethod(
                  signatureType,
                  props.flowType,
                  props.defaultAuthenticationMethod
                )
              )
          )
        )
    )
  );
};

function mapSignatureToExistingClientAuthMethod(
  signatureType: loanApi.SignatureType,
  flowType: StandardLoanFlowType,
  defaultAuthenticationMethod: Option<ExistingClientAuthenticationMethod>
): Option<ExistingClientAuthenticationMethod> {
  if (flowType === "TLSAgent") {
    return defaultAuthenticationMethod;
  }
  if (flowType === "SmartBanking") {
    return option.some("PIN");
  }
  switch (signatureType) {
    case "OtpSms":
      return option.some("ID");
    case "Click2Sign":
      return option.some("PUSH_NOTIFICATION");
  }
}
