import {
  Box,
  convertToBlob,
  Dialog,
  downloadFile,
  DownloadIcon,
  unsafeLocalizedString,
  useAuthenticationContext,
  useDialogDimensionLimits,
} from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../../../intl";
import { DocumentViewer } from "../../DocumentViewer/DocumentViewer";
import * as remoteData from "../../../RemoteData";
import * as api from "../../documentAPI";
import { constant, constNull, pipe } from "fp-ts/function";
import { useQuery } from "../../../useAPI";
import { spaceUnit } from "design-system/lib/styleConstants";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { Option } from "fp-ts/Option";
import { TaskEither } from "fp-ts/TaskEither";
import { getLatestToken } from "../../../APICall";

type ProductType = "SL" | "Mortgage";

export type DocumentViewDialogProps = {
  documentId: NonEmptyString;
  onClose: () => void;
  productType: ProductType;
  downloadAction?: TaskEither<unknown, string>;
};

function foldProduct<T>(
  productType: ProductType,
  match: { whenSL: () => T; whenMortgage: () => T }
) {
  switch (productType) {
    case "Mortgage":
      return match.whenMortgage();
    case "SL":
      return match.whenSL();
  }
}

export const DocumentViewDialog = (props: DocumentViewDialogProps) => {
  const { documentId, onClose } = props;
  const formatMessage = useFormatMessage();

  const { heightLimits } = useDialogDimensionLimits();

  const { maxHeight } = heightLimits();

  const [documentContent] = useQuery(
    foldProduct(props.productType, {
      whenSL: () => api.getDocumentContentSL,
      whenMortgage: () => api.getDocumentContentMortgage,
    }),
    {
      documentID: documentId,
    }
  );

  const getDownloadUrl = (
    downloadUrl: Option<NonEmptyString>,
    file: api.GetDocumentContentOutput
  ) =>
    pipe(
      downloadUrl,
      option.getOrElse(() =>
        URL.createObjectURL(convertToBlob(file.content, file.mimeType))
      )
    );

  const { authInfo } = useAuthenticationContext();

  return pipe(
    documentContent,
    remoteData.fold(constNull, constNull, docContent => (
      <Dialog
        variant="left"
        size="medium"
        title={unsafeLocalizedString(docContent.filename)}
        actions={[
          {
            variant: "text",
            label: formatMessage("Download.buttonLabel"),
            icon: DownloadIcon,
            action() {
              downloadFile(
                getDownloadUrl(docContent.downloadUrl, docContent) +
                  pipe(
                    getLatestToken(authInfo),
                    option.map(token => "&authorization=" + token),
                    option.getOrElse(constant(""))
                  ),
                docContent.filename
              );
            },
          },
          {
            variant: "primary",
            label: formatMessage("Close"),
            action: onClose,
          },
        ]}
        onDismiss={option.some(onClose)}
      >
        <Box
          style={{
            minHeight: maxHeight ? maxHeight - 60 * spaceUnit : "70vh",
          }}
        >
          <DocumentViewer
            name={docContent.filename}
            type={docContent.mimeType}
            data={docContent.content}
          />
        </Box>
      </Dialog>
    ))
  );
};
